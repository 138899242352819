import { Component, OnInit } from '@angular/core';
import { Icdiplan } from '../../models/icdiplan';
import { ActivatedRoute } from '@angular/router';
import { RecordsService } from '../../services/records.service';
import { RmsService } from '../../services/rms.service';

declare var $:any;
declare var swal:any;

@Component({
  selector: 'app-map-detail-view',
  templateUrl: './map-detail-view.component.html',
  styleUrls: ['./map-detail-view.component.css']
})
export class MapDetailViewComponent implements OnInit {
  resultSet:Array<Icdiplan>;
  maprefNber: any;
  accessLevel:number = 1;

  isEdit = false;
  isSavingEdits = false;
  PlansSheetTypeList: any[] = [];
  selectedSheet:string = "";
  pagination = { current: 1};

  isResult:boolean = false;

  constructor(
    private _service: RecordsService,
    private _rms_service: RmsService,
    private _activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.maprefNber = this._activatedroute.snapshot.params['fileName'];
    this.checkAccessLevel();
    // load sheet type
    this._service.getCustomGeneralServerInfo().subscribe((types) =>{
      // assign custom server info
      this.PlansSheetTypeList = types.listPlansType;
    });
    setTimeout(e=>{
      this._service.currentDataSourceFilteredFromMap.subscribe((res: Array<Icdiplan>) => {
        if(res.length > 0){
          if(res[0].FileName === undefined){
            this.isResult = false;
          }
          else{
            this.isResult = true;
            res.forEach((p, i) =>{
              if(p.FileName.toLocaleLowerCase() === this.maprefNber.toLocaleLowerCase()) this.pagination.current = i+1;
            });
          }
        }
        this.resultSet = res;
      });
    },500);
  }

  checkAccessLevel(){
    this._rms_service.AccessLevel.subscribe(al =>{
      this.accessLevel = al;
    });
  }

  deletePlan(filename:string, domEl):void{
    swal({
      title: 'Are you sure?',
      text: `You will not be able to recover this file - ${filename}.pdf`,
      buttons: true,
      dangerMode: true,
    }).then(action =>{
      if(action){
        //delete Plan
        // get the logged in user email
      var __email = document.getElementById("logged-in-user-id").innerText;
        this._service.deleteSheet(filename, __email).subscribe(res =>{
          if(res){
            for(var i = 0; i < this.resultSet.length; i++){
              if(this.resultSet[i].FileName == filename){
                this.resultSet.splice(i, 1);
                //this.filteredCount.count --;
              }
            }
          }
        })
  
      }
    })
  }

  getClass(isYes){
    return isYes == 1  ? "badge bg-success": "badge bg-danger";
  }

  shareItem(elmt, plan, displayType){
    let that = this;
    if(that._rms_service.plansShared.length < 10){
      var isSuccessMessage = true;
      var itemAddedMessage = "Plan " + plan.FileName + " added to cart";
      
     // console.log($($(elmt.target)[0]).parents());
      var btnParentDiv;
      if(navigator.userAgent.indexOf("Chrome") > -1){
        btnParentDiv = $($(elmt.target)[0]).parents()[3];
      }
      else{
        btnParentDiv = $($(elmt.target)[0]).parents()[2];
      }
      //var isChrome = !!window.chrome && !!window.chrome.webstore;
     let offset = $(btnParentDiv).offset();
      $(btnParentDiv).clone().addClass('product-clone').css({
        'left' : offset.left + 'px',
        'top' : (offset.top-$(window).scrollTop()) + 'px',
        'width' :  $(btnParentDiv).width() + 'px',
        'height' : $(btnParentDiv).height() + 'px'
      }).appendTo($('.product').parent());
      var cart = $('.shoping-cart').offset();

      let addToCart = function(){
        if(that._rms_service.plansShared.length > 0){
          for (var i = 0; i < that._rms_service.plansShared.length; i++) {
            if(that._rms_service.plansShared[i].FileName == plan.FileName){
              that._rms_service.plansShared.splice(i, 1);
              itemAddedMessage = "Plan " + plan.FileName + " already exists in your cart";
              isSuccessMessage = false;
            }
          }
        }
        that._rms_service.plansShared.push(plan);
        $(".added-text").text(itemAddedMessage)
        if(isSuccessMessage)
          $(".item-added").show('fadeIn');
        else
          $(".item-non-added").show('fadeIn');
        
        setTimeout(function(){
          if(isSuccessMessage)
            $(".item-added").hide('fadeOut');
          else
            $(".item-non-added").hide('fadeOut');
        }, 1000);
      };
      switch (displayType){
        case "details":
          addToCart()
          break;
        default:
          $('.product-clone').animate( { top: (cart.top-$(window).scrollTop()) + 'px', left: cart.left + 'px', 'height': '0px', 'width': '0px' }, 800, function(){
            $(this).remove();
            addToCart()
          });
          break;
      }
    }
    else{
      swal('You can only share 10 items at a time!');
    }
}

enableEditSheet(selectedFile:string):void{
  this.selectedSheet = selectedFile;
  this.isEdit = !this.isEdit;
}

saveEdits(plan:Icdiplan){
  let _saveSheetInfoObj = {
    FileName : plan.FileName,
    SheetTypeUID: plan.SheetTypeUID,
    SheetType:plan.SheetType,
    SheetStreet: plan.SheetStreets,
    SheetNumber: plan.SheetNumber,
    HasSanitary: plan.HasSanitary,
    HasStorm: plan.HasStorm,
    HasWater: plan.HasWater,
    HasSWMP: plan.HasSWMP,
    HasBridge: plan.HasBridge
  };
  this.isSavingEdits = true;
  this.isEdit = false;
   this._service.saveSheetEdits(_saveSheetInfoObj)
      .subscribe(res =>{
        this.isSavingEdits = false;
        this.reloadPlanSet(plan.FileName);
      })
}

reloadPlanSet(filename){
  this._service.loadPlansBySet(filename).subscribe(res =>{
    this.resultSet = res;
   });
}

}
