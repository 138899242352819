import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { routes } from './rms.routes';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { Ng2OrderModule } from 'ng2-order-pipe';

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// services
import { AuthenticationService } from './services/authentication.service';
import { AuthguardService } from './services/authguard.service';
import { RecordsService } from './services/records.service';
import { RmsService } from './services/rms.service';

import { AppComponent } from './app.component';
import { ImgFallbackModule } from 'ngx-img-fallback';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { ResultsComponent } from './results/results.component';
import { MapSearchComponent } from './map-search/map-search.component';
import { PlanArchiveComponent } from './plan-archive/plan-archive.component';
import { UtilityFilterPipe } from './utility-filter.pipe';
import { UtilCustomFilterPipe } from './util-custom-filter.pipe';

import { AdvancedFilterPipe } from './services/advanced-filter.pipe';
import { UserService } from './services/user.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { ListViewComponent } from './list-view/list-view.component';
import { TableViewComponent } from './table-view/table-view.component';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { MapListViewComponent } from './map-result/map-list-view/map-list-view.component';
import { MapTableViewComponent } from './map-result/map-table-view/map-table-view.component';
import { MapDetailViewComponent } from './map-result/map-detail-view/map-detail-view.component';
import { ResultMapComponent } from './map-result/result-map/result-map.component';
import { ResultsNavbarComponent } from './results-navbar/results-navbar.component';
import { SettingsComponent } from './settings/settings.component';
import { RmsOrderPipe } from './rms-order.pipe';
import { BobFilterPipe } from './bob-filter.pipe';
import { LogsComponent } from './logs/logs.component';
import { BobOrderPipe } from './bob-order.pipe';

//https://github.com/VadimDez/ngx-order-pipe

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    AboutComponent,
    HomeComponent,
    SearchComponent,
    AdvancedSearchComponent,
    ResultsComponent,
    MapSearchComponent,
    PlanArchiveComponent,
    UtilityFilterPipe,
    UtilCustomFilterPipe,
    AdvancedFilterPipe,
    ListViewComponent,
    TableViewComponent,
    DetailViewComponent,
    MapListViewComponent,
    MapTableViewComponent,
    MapDetailViewComponent,
    ResultMapComponent,
    ResultsNavbarComponent,
    SettingsComponent,
    RmsOrderPipe,
    BobFilterPipe,
    LogsComponent,
    BobOrderPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
    HttpModule,
    routes,
    NgxPaginationModule,
    Ng2OrderModule,
    BrowserAnimationsModule,
    ImgFallbackModule
    //MatTableModule
  ],
  providers: [AuthenticationService, AuthguardService, RecordsService, RmsService, 
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
