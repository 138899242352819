import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms/src/directives/ng_form';
import { RecordsService } from '../services/records.service';
import { Router } from '@angular/router';
import { RmsService } from '../services/rms.service';
import { HttpClient } from '@angular/common/http';
declare var swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginMessage: string;
  isSigningIn = false;
  userEmail: string;
  password: string;
  
  constructor(
    private _rmsService: RmsService,
    private _recordService: RecordsService,
    private _router: Router, private _httpClient: HttpClient) { }

  ngOnInit() {}

   SignIn(loginForm: NgForm) {
    if (loginForm.valid) {
      this.isSigningIn = true;
      this._rmsService.getUserToken(loginForm.value.email, loginForm.value.pw)
      .then((res: any) => {
        // console.log(res);
        this.isSigningIn = false;
        if (res.status === 400) {
          this.loginMessage = 'The email or password is incorrect.';
        }else if (res.AccessLevel <= 2) {
            this.loginMessage = '';
            swal('You may not have the right access to this site. Please contact the Engineering Department.');
          } else {
            this._router.navigate(['/home']);
          }
      })
      .catch(err => console.log(err));
    }
  }
}
