import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  appVersion: string = '2.3.5';
  versionDate: string = '01/22/2020';
  constructor() { }

  ngOnInit() {
  }

}
