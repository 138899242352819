import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RecordsService } from '../services/records.service';
import { RmsService } from '../services/rms.service';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { PlanObj } from '../models/planObj';
import { RmsFilterObj } from '../models/rmsFilterObj';
import { DataStore } from '../models/data-store';

declare var $:any;
declare var _:any;

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit {
  _search_option: number = 0;
  _dbstore: DataStore

  isResult:boolean = false;
  isSearched:boolean = false;

  advFilter:RmsFilterObj;
  pageIndex:number = 1;
  pageSize:number = 100;
  resultset:any[] = [];
  globalResultSet:any[] = [];
  requestUID:number = 0;
  queryBuilder:any = [];
  queryBuilderDisplay:any = [];
  queryStringBuilder:any = [];
  dtblSearchCriteriaTrail:any[] = [];
  isSearching:boolean = false;
  sharedSetTitle:string = "";
  isSearchComplete:boolean = false;
  isNoResult:boolean = false;

  userPreferences: any = {
    fileNameOrder: false,
    explicitSearch:false
  };

  constructor(
    private _rmsService:RecordsService,
    public _sharedResults:RmsService,
    private _route:ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit() {
    var __user_settings_storage = localStorage.getItem("__userSettings");
    if(__user_settings_storage){
      var __current_settings = JSON.parse(__user_settings_storage);
      this.userPreferences.fileNameOrder = __current_settings.fileNameOrder;
      this.userPreferences.explicitSearch = __current_settings.explicitSearch;
    }

    this.resetQuery();
    this.InitSearchCriteriaTrail();
    //console.log(this._sharedResults.searchResutlsSharedAdvance);
    this.dtblSearchCriteriaTrail = this._sharedResults.searchResutlsSharedAdvance.dtblSearchCriteriaTrail;
  }

  InitSearchCriteriaTrail(){
    this._rmsService.InitSearchCriteriaTrail().subscribe(e=>{});
  }

  getQuery(queryString: string){
    let query = "", wordArray = [];

    if(this.userPreferences.explicitSearch){
      query = queryString;
    }
    else{
        let words = queryString.split(" ");
        wordArray = words;
        if(wordArray.length > 0){
          for(var i = 0; i< wordArray.length; i++){
            query = query + wordArray[i] + (i === wordArray.length -1 ? "" : "{AND}");
          }
        }
        else
          query = queryString;
        }
    return query;
  }

  resetForm(){
    this.advFilter = {
      name: "",
      sheet:"",
      year:"",
      title:"",
      street:""
    };
    this.queryBuilder = [];
    this.queryStringBuilder = [];
    this.resultset = [];
    this.globalResultSet = [];
    this.dtblSearchCriteriaTrail = [];
    this._sharedResults.searchResutlsSharedAdvance.searchName = "";
    this._sharedResults.searchResutlsSharedAdvance.searchTitle = "";
    this._sharedResults.searchResutlsSharedAdvance.searchYear = "";
    this._sharedResults.searchResutlsSharedAdvance.searchStreets = "";
    this._sharedResults.searchResutlsSharedAdvance.resultArray = [];
    this._sharedResults.searchResutlsSharedAdvance.queryStringBuilder = [];
    this._sharedResults.searchResutlsSharedAdvance.dtblSearchCriteriaTrail = [];
    this.isSearching = false;
    this.isSearchComplete = false;
  }

  getPlansByFieldAndValue(fieldName, fieldValue){
    if(fieldValue != ""){
      this.isSearching = true;
      this.queryStringBuilder.push({fieldName:fieldName, fieldValue: fieldValue});
      this.queryBuilder.push({fieldName:fieldName, fieldValue: fieldValue, totalRecords: this.resultset.length});
      this.queryBuilderDisplay.push({uid: fieldName + this.requestUID, fieldName:fieldName, fieldValue: fieldValue, totalRecords: this.resultset.length});
      this.globalResultSet.push({uid: fieldName + this.requestUID, dataset: this.resultset});
      this.getPlansSheets();
    }
  }

  getPlansSheets(){
    if(this.userPreferences.explicitSearch)
      this._search_option = 1;

    this._rmsService.findPlansByStringQueryBuilder(this.queryStringBuilder, this._search_option).subscribe(res =>{
      // console.log(res);
      this.isSearching = false;
      this.isSearched = true;
      if(res.g_dtblSheet.length == 0){
        this.isNoResult = true;
        this.isResult = false;
      }
      else{
        this.isNoResult = false;
        this.isResult = true;
      }

      this.dtblSearchCriteriaTrail = res.g_dtblSearchCriteriaTrail;
      this.resultset = res.g_dtblSheet;

      // update the data store
      this._rmsService.changeDataSource(res.g_dtblSheet);
      this._rmsService.changeDataSourceFilter(res.g_dtblSheet);
      this.checkResultSet();
      this.advFilter = {
        name: "",
        sheet:"",
        year:"",
        title:"",
        street:""
      };

      // navigate to result set
      //[1] check on which route to redirect
      let _route = "result";
      switch (this._router.url) {
        case "/advanced/result/tbl":
          _route = "result/tbl";
          break;
          case "/advanced/result/detail":
          _route = "result/detail"
          break;
        default:
        break;
    }
    if(this.resultset.length > 0){
      this._router.navigate(['/advanced/' + _route]);
    }else{
      this._router.navigate(['/advanced/']);
    }
    })
  }

  checkResultSet(){
    if(this._sharedResults.searchResutlsSharedAdvance.dtblSearchCriteriaTrail.length > 0){

    }
    else{
      //this._sharedResults.searchResutlsSharedAdvance.resultArray = this.resultset;
      this._sharedResults.searchResutlsSharedAdvance.dtblSearchCriteriaTrail = this.dtblSearchCriteriaTrail;
      this._sharedResults.searchResutlsSharedAdvance.queryStringBuilder = this.queryStringBuilder;
    }

  }

  removeItemFromQBuilder(index){
    this.queryStringBuilder.splice(index, 1);
    this.dtblSearchCriteriaTrail.splice(index, 1);
    this.queryBuilder.splice(index, 1);
    this.queryBuilderDisplay.splice(index, 1);

    if(this.dtblSearchCriteriaTrail.length > 0){
      this.getPlansSheets();
    }
    else{
      this.resetForm();
      this.resetQuery();
      this.isNoResult = false;
      this.resultset = [];
      this.isSearched = false;
      this._rmsService.changeDataSource(this.resultset);
      this._rmsService.changeDataSourceFilter(this.resultset);
    }
  }
  resetQuery(){
    this.advFilter = {
      name: "",
      sheet:"",
      year:"",
      title:"",
      street:""
    };
    if(this._sharedResults.searchResutlsSharedAdvance.resultArray.length > 0){
      this.dtblSearchCriteriaTrail = this._sharedResults.searchResutlsSharedAdvance.dtblSearchCriteriaTrail;
      this.resultset = this._sharedResults.searchResutlsSharedAdvance.resultArray;
      this.queryStringBuilder = this._sharedResults.searchResutlsSharedAdvance.queryStringBuilder;
    }
  }

}
