import { Component, OnInit } from '@angular/core';
import { Icdiplan } from '../models/icdiplan';
import { RecordsService } from '../services/records.service';
import { RmsService } from '../services/rms.service';

declare var $:any;
declare var swal:any;

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.css']
})
export class ListViewComponent implements OnInit {
  sortName:string = "fas fa-sort";
  sortNameIcon:string = "fas fa-sort";
  sortYearIcon:string = "fas fa-sort";
  sortup: string = "fas fa-sort-up";
  sortdown: string = "fas fa-sort-down";
  isSortedName: boolean = false;
  isSortedYear: boolean = false;
  orderField: string;

  resultSet:any;
  isResultSet: boolean = false;

  pagination = { current: 1};
  noThumbnail:string = "../../assets/img/no-image-available.png";

  constructor(
    private _recordService: RecordsService,
    private _rmsService: RmsService
  ) {}

  ngOnInit() {
    this._recordService.currentDataSourceFiltered.subscribe(res =>{
      this.resultSet = res;
      if(this.resultSet.length > 0) {
        this.isResultSet = true;
      }else{this.isResultSet = false;}
    })
  }

  sortResults(type) {
    switch(type){
      case 'name':
          this.isSortedName = !this.isSortedName;
          this.orderField = type;
          this.sortName = this.isSortedName ? this.sortup : this.sortdown;
          this.sortNameIcon = this.isSortedName ? this.sortup : this.sortdown;
        break;
      case 'year':
          this.isSortedYear = !this.isSortedYear;
          this.orderField = type;
          this.sortName = this.isSortedYear ? this.sortup : this.sortdown;
          this.sortYearIcon = this.isSortedYear ? this.sortup : this.sortdown;
        break;
    }

  }

  getClass(isYes){
    return isYes == 1  ? "badge bg-success": "badge bg-danger";
  }
  shareItem(elmt, plan, displayType){
    let that = this;
    if(that._rmsService.plansShared.length < 10){
      var isSuccessMessage = true;
      var itemAddedMessage = "Plan " + plan.FileName + " added to cart";

     // console.log($($(elmt.target)[0]).parents());
      var btnParentDiv;
      if(navigator.userAgent.indexOf("Chrome") > -1){
        btnParentDiv = $($(elmt.target)[0]).parents()[3];
      }
      else{
        btnParentDiv = $($(elmt.target)[0]).parents()[2];
      }
      //var isChrome = !!window.chrome && !!window.chrome.webstore;
     let offset = $(btnParentDiv).offset();
      $(btnParentDiv).clone().addClass('product-clone').css({
        'left' : offset.left + 'px',
        'top' : (offset.top-$(window).scrollTop()) + 'px',
        'width' :  $(btnParentDiv).width() + 'px',
        'height' : $(btnParentDiv).height() + 'px'
      }).appendTo($('.product').parent());
      var cart = $('.shoping-cart').offset();

      let addToCart = function(){
        if(that._rmsService.plansShared.length > 0){
          for (var i = 0; i < that._rmsService.plansShared.length; i++) {
            if(that._rmsService.plansShared[i].FileName == plan.FileName){
              that._rmsService.plansShared.splice(i, 1);
              itemAddedMessage = "Plan " + plan.FileName + " already exists in your cart";
              isSuccessMessage = false;
            }
          }
        }
        that._rmsService.plansShared.push(plan);
        $(".added-text").text(itemAddedMessage)
        if(isSuccessMessage)
          $(".item-added").show('fadeIn');
        else
          $(".item-non-added").show('fadeIn');

        setTimeout(function(){
          if(isSuccessMessage)
            $(".item-added").hide('fadeOut');
          else
            $(".item-non-added").hide('fadeOut');
        }, 1000);
      };
      switch (displayType){
        case "details":
          addToCart()
          break;
        default:
          $('.product-clone').animate( { top: (cart.top-$(window).scrollTop()) + 'px', left: cart.left + 'px', 'height': '0px', 'width': '0px' }, 800, function(){
            $(this).remove();
            addToCart()
          });
          break;
      }
    }
    else{
      swal('You can only share 10 items at a time!');
    }
}

}
